// Packages
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { AircraftCostPlusInvoicesListRequest, AircraftBlockHoursInvoicesListRequest, ContractsListRequest, AircraftByContractListRequest, AircraftInvoicesExportRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle } from '../../../utils';
import { auth, contractsCache } from '../../../atoms';
import { Enter, Modal, InvoiceReportTable, Loader } from '../../../components';
import { ExportAircraftInvoiceForm } from './components';

function AircraftInvoicesList() {

  const [authState, _authState] = useAtom(auth);
  const [contract, _contract] = useAtom(contractsCache);
  const [aircraftCostPlusInvoicesItems, _aircraftCostPlusInvoicesItems] = useState({});
  const [aircraftBlockHoursInvoicesItems, _aircraftBlockHoursInvoicesItems] = useState({});
  const [aircrafts, _aircrafts] = useState([]);
  const [selectedContract, _selectedContract] = useState('');
  const [selectedAircraft, _selectedAircraft] = useState('');
  const [selectedMonth, _selectedMonth] = useState('');
  const [selectedYear, _selectedYear] = useState(dayjs().format('YYYY'));
  const [invoiceType, _invoiceType] = useState('cost-plus');
  const [showExportInvoiceModal, _showExportInvoiceModal] = useState(false);
  const [isLoading, _isLoading] = useState(false);

  // Get contract either from cache or from server
  useEffect(() => {
    if (authState) {
      if (!contract || !contract.created || Date.now() - contract.created >= 1200000) {
        getContract();
      }
    }
  }, [authState]);

  // Get aircrafts for selected contract
  useEffect(() => {
    if (selectedContract)
      getAircraftsByContract();
  }, [authState, selectedContract]);

  // Get aircraft invoices when invoiceType, aircraft, contract, month and year are selected
  useEffect(() => {
    if (authState && selectedAircraft && selectedMonth && selectedYear && invoiceType === 'cost-plus') {
      getAircraftCostPlusInvoicesList();
    }
    else if (authState && selectedAircraft && selectedMonth && selectedYear && invoiceType === 'block-hours') {
      getAircraftBlockHoursInvoicesList();
    }
  }, [authState, selectedAircraft, selectedMonth, selectedYear, invoiceType]);

  const getContract = () => {
    const token = decodeString(authState);
    ContractsListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _contract({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch contracts' });
        }
      )
  };

  const getAircraftsByContract = () => {
    const token = decodeString(authState);
    AircraftByContractListRequest(token, selectedContract).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        nonPaginatedData.forEach(A => A.selected = false);
        // Keep server data in local state
        _aircrafts({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircrafts" });
    });
  };

  const getAircraftCostPlusInvoicesList = () => {
    _isLoading(true);
    const token = decodeString(authState);
    const aircraftId = selectedAircraft;
    const data = JSON.stringify({
      month: parseInt(selectedMonth),
      year: parseInt(selectedYear)
    });
    AircraftCostPlusInvoicesListRequest(token, aircraftId, data)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        _isLoading(false);
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData?.invoice_service_data) {
          _aircraftCostPlusInvoicesItems(old => ({
            invoice_items: nonPaginatedData?.invoice_service_data?.service_data &&
              nonPaginatedData?.invoice_service_data?.service_data.map((item => ({
                ...item,
                month: dayjs(`${selectedYear}-${selectedMonth}-01`)?.format('MMM-YYYY')
              }))),
            total: nonPaginatedData?.invoice_service_data?.total_stats?.net_total_cost || 0
          }))
        } else {
          _aircraftCostPlusInvoicesItems({});
          throw "Request Failed";
        }
      })
      .catch(err => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircraft invoices" });
      })
  };

  const getAircraftBlockHoursInvoicesList = () => {
    _isLoading(true);
    const token = decodeString(authState);
    const aircraftId = selectedAircraft;
    const data = JSON.stringify({
      month: parseInt(selectedMonth),
      year: parseInt(selectedYear)
    });
    AircraftBlockHoursInvoicesListRequest(token, aircraftId, data)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        _isLoading(false);
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData?.block_hour_data) {
          _aircraftBlockHoursInvoicesItems(old => ({
            invoice_items: nonPaginatedData?.block_hour_data &&
              nonPaginatedData?.block_hour_data.map((item => ({
                ...item,
                month: dayjs(`${selectedYear}-${selectedMonth}-01`)?.format('MMM-YYYY')
              }))),
            total: nonPaginatedData?.total_stats?.total_blockhours || 0
          }))
        } else {
          _aircraftBlockHoursInvoicesItems({});
          throw "Request Failed";
        }
      })
      .catch(err => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircraft invoices" });
      })
  };

  // *********** Handlers ***********

  const handleContractChange = (e) => {
    _selectedContract(e.target.value);
    _selectedAircraft("");
  };

  const handleMonthChange = (e) => {
    _selectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    _selectedYear(e.target.value);
  };

  const handleAircraftChange = (e) => {
    _selectedAircraft(e.target.value);
  };

  const handleInvoiceTypeChange = (value) => {
    _invoiceType(value);
  };

  const handleExportInvoice = (data) => {
    const token = decodeString(authState);
    AircraftInvoicesExportRequest(token, data)
      .then((res) => {
        if (res?.status === 200) {
          handleCloseInvoiceExportModal();
          Store.addNotification({ ...constants.SUCCESSTOAST, message: "Invoices exported successfully" });
        }
        else if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        }
        else throw 'operation failed'
      }).catch((err) => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to export invoices" });
      });
  };

  const handleOpenInvoiceExportModal = () => {
    _showExportInvoiceModal(true);
  };

  const handleCloseInvoiceExportModal = () => {
    _showExportInvoiceModal(false);
  };

  // *********** Render Functions ***********

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const EXPORT_INVOICE_BUTTON = () => (
    <div className='flex w3-margin-bottom w3-margin-top'>
      <div className='ml-auto'>
        <div className='flex wrap'>
          <button className='flex items-center w3-btn bg-primary-blue w3-text-white' onClick={handleOpenInvoiceExportModal}>
            <Enter className='w-5 h-5 w3-text-white rotate-180' /> <span className='px-1'> Export Invoices </span>
          </button>
        </div>
      </div>
    </div>
  );

  const CONTRACT_SELECTION = () => (
    <div className='w3-row-padding w3-stretch'>
      <div className="w3-col m6 s6">
        <select
          value={selectedContract}
          onChange={handleContractChange}
          className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
        >
          <option value={""} disabled> Select Contract </option>
          {contract &&
            contract?.data &&
            contract?.data
              ?.map((C, index) => (
                <option value={C.id} key={index}> {C.name} </option>
              ))}
        </select>
      </div>
      <div className="w3-col m6 s6">
        <select
          disabled={!selectedContract}
          value={selectedAircraft}
          onChange={handleAircraftChange}
          className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
        >
          <option value={""} disabled> Select Aircraft </option>
          {aircrafts?.data && aircrafts?.data?.map((aircraft, index) => (
            <option key={index} value={aircraft.id}>{aircraft.tailnumber}</option>
          )
          )}
        </select>
      </div>
    </div>
  );

  const DATE_SELECTION = () => (
    <div className='w3-row-padding w3-stretch'>
      <div className="w3-col m6 s6">
        <select
          value={selectedMonth}
          onChange={handleMonthChange}
          className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
        >
          <option value={""} disabled> Select Month </option>
          {constants?.MONTHS?.map((option, index) => <option key={option} value={index + 1}> {option} </option>)}
        </select>
      </div>
      <div className="w3-col m6 s6" >
        <select
          name='year'
          value={selectedYear}
          onChange={handleYearChange}
          className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
        >
          <option value={""} disabled> Select Year </option>
          {Array.from({ length: parseInt(dayjs().year()) - 2024 + 1 }, (_, i) => i + 2024).map((year) => (
            <option value={year} key={year}> {year} </option>
          ))
          }
        </select>
      </div>
      <div className="w3-col m12 w3-bar" >
        <div
          onClick={e => handleInvoiceTypeChange('cost-plus')}
          className={`w3-col w3-half w3-button ${invoiceType === "cost-plus" ? 'bg-primary-blue w3-text-white' : 'w3-light-grey'}`}
        >
          Cost Plus
        </div>
        <div
          onClick={e => handleInvoiceTypeChange('block-hours')}
          className={`w3-col w3-half w3-button ${invoiceType === "block-hours" ? 'bg-primary-blue w3-text-white' : 'w3-light-grey'}`}
        >
          Block Hours
        </div>
      </div>
    </div>
  );

  const INVOICE_ITEMS_LIST_TABLE = () => (
    <InvoiceReportTable
      costPlusInvoiceItems={aircraftCostPlusInvoicesItems}
      blockHoursInvoiceItems={aircraftBlockHoursInvoicesItems}
      isLoading={isLoading}
      invoiceType={invoiceType}
    />
  );

  const EXPORT_AIRCRAFT_INVOICES_MODAL = () => (
    showExportInvoiceModal
    && <Modal isOpen={showExportInvoiceModal} modalClass="w-modal p-1 w3-round-medium">
      <div className="w-full p-2">
        <h3> Export Aircraft Invoices </h3>
        <ExportAircraftInvoiceForm
          contract={contract}
          handleExportInvoice={handleExportInvoice}
          handleClose={handleCloseInvoiceExportModal}
        />
      </div>
    </Modal>
  );

  const SELECT_CONTRACT_MESSAGE = () => (
    <div className='w-full w3-margin-top text-center w3-text-gray'> Select contract & month to see invoice items </div>
  );

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {EXPORT_INVOICE_BUTTON()}
        {CONTRACT_SELECTION()}
        {DATE_SELECTION()}
        {EXPORT_AIRCRAFT_INVOICES_MODAL()}
        {selectedMonth && selectedContract && selectedAircraft
          ? isLoading ? LOADER() : INVOICE_ITEMS_LIST_TABLE()
          : SELECT_CONTRACT_MESSAGE()
        }
      </div>
    </div>
  );

  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default AircraftInvoicesList;