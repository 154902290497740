// Packages
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import dayjs from 'dayjs';

// APIs
import { BlockHourInvoiceDetailsRequest, BlockHourInvoiceListRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle, ignoreTimeZone } from '../../../utils';
import { airbasesCache, auth, fleetCache } from '../../../atoms';
import { TableLite, Edit, Plus, Refresh } from '../../../components';

function BlockHourInvoicesList() {

  const Navigate = useNavigate();
  const [isLoading, _isLoading] = useState(false);
  const [blockHourInvoices, _blockHourInvoices] = useState([]);
  const [authState, _authState] = useAtom(auth);

  // Get users either from cache or from server
  useEffect(() => {
    if (authState) {
      getBlockHourInvoices();
    }
  }, [authState]);

  const getBlockHourInvoices = (page = undefined) => {
    const token = decodeString(authState);
    _isLoading(true);
    BlockHourInvoiceListRequest(token, page).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(
      data => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in local state with current time
          _blockHourInvoices({
            data: [...data.results?.map(invoice => (
              {
                ...invoice,
                invoice_number: invoice?.invoice_number || '-',
                createdat: dayjs(ignoreTimeZone(invoice?.createdat))?.format('YYYY-MM-DD')
              }
            ))],
            pagination: {
              currentPage: page,
              totalPages: Math.ceil(data?.count / constants.PAGINATIONPERPAGE)
            },
            created: Date.now()
          });
          _isLoading(false);
        } else {
          throw 'Request Failed';
        }
      }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch block hour invoices' });
        }
      )
  };

  const BlockHourInvoiceheaders = ["id", "request_id", "createdat"];

  const CustomHeaders = {
    "id": "Invoice Id",
    "request_id": "Request Id",
    "invoice_number": "Invoice No.",
    "createdat": "Created Date",
  };

  // *********** Handlers ***********

  const handlePaginate = (pageNo) => {
    getBlockHourInvoices(pageNo);
  };

  const routeToEditBlockHourInvoice = (id) => {
    Navigate(`/block-hour-invoices/edit/${id}`);
  };

  const routeToViewBlockHourInvoice = (id) => {
    Navigate(`/block-hour-invoices/view/${id}`);
  };  

  // *********** Render Functions ***********

  const CUSTOM_EDIT_BTN = {
    "render":
      <button
        style={{ "color": "black" }}
        className={"custom-edit-fsr-btn small-left-margin"}
      >
        <span className="w3-tooltip">
          <span className='tooltip-text w3-text w3-tag w-12'>
            Edit
          </span>
          <Edit className='h-5 w-5' />
        </span>
      </button>
    ,
    "className": "custom-edit-fsr-btn"
  };

  const CONTENT = () => (
    <div className='page-content w3-white h-full relative overflow-hidden'>
      <div className='py-2'>
        {/* {NEW_USER_BUTTON()} */}
        <div className='list-view-container overflow-auto'>
          <TableLite
            showActions={true}
            data={blockHourInvoices && blockHourInvoices?.data || []}
            headers={BlockHourInvoiceheaders}
            customHeaders={CustomHeaders}
            actionTypes={['view', 'edit']}
            showPagination={true}
            totalPages={blockHourInvoices?.pagination?.totalPages || 1}
            currentPage={blockHourInvoices?.pagination?.currentPage || 1}
            renderEdit={CUSTOM_EDIT_BTN}
            onRowEdit={(event, row) => routeToEditBlockHourInvoice(row.id)}
            onRowView={(event, row) => routeToViewBlockHourInvoice(row.id)}
            onPaginate={(pageNo) => handlePaginate(pageNo)}
            cellStyle={{ fontSize: '0.8em' }}
            noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {CONTENT()}
    </div>
  )
}

export default BlockHourInvoicesList;