import { constants } from "../utils";

const AircraftInvoicesExportRequest = (token, data) => {
  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", `Bearer ${token}`);
  requestHeaders.append("Content-Type", "application/json");  
  return fetch(`${constants.BASEURL}/api/aircraft/generate_invoices_for_aircraft/`, {
    method: "POST",
    body: data,
    headers: requestHeaders,
    redirect: "follow",
  });
};

export default AircraftInvoicesExportRequest;
