// Packages
import React, { useState } from 'react';
import Select from 'react-select';

// Utils
import { Modal } from '../../../../components';

function CostListItemModal({
  legs,
  services,
  allowedRoles,
  selectedCostListItem,
  showCostListItemModal,
  handleSaveCostListItem,
  handleChangeCostItemField,
  handleCloseCostListItemModal
}) {

  // The services to be selected in the new cost plus item can only be of type which is allowed in the user role
  let FilteredRolesServices = { ...services } || { data: [] };
  FilteredRolesServices.data = FilteredRolesServices?.data?.filter(S => allowedRoles?.map(role => role?.toUpperCase())?.includes(S?.serviceCategory?.toUpperCase()));
  if (allowedRoles?.find(role => ["Hotel", "hotel", "hotac"].includes(role))) {
    FilteredRolesServices.data = [
      ...FilteredRolesServices.data,
      ...services?.data?.filter(S => S?.name?.includes('otel'))
    ];
  }  

  // *********** Handlers ***********

  return (
    <Modal isOpen={showCostListItemModal} modalClass="w3-round-medium">
      <div className="w-full">
        <div className='py-1 px-2 flex flex-wrap items-center'>
          <h3>
            Cost List Item
          </h3>
        </div>
        <div className='py-1'>
          <div className='w3-row-padding'>
            <div className='w3-col s12 m6 l6'>
              <div>
                <label> Select Leg </label>
                <Select
                  className='w-full small-top-margin small-bottom-margin'
                  options={legs && legs?.filter(L => L?.is_local)?.map(L => ({
                    value: L?.id,
                    label: `
                    ${L?.departure_airport_icao} / ${L?.departure_airport_iata} 
                    → ${L?.destination_airport_icao} / ${L?.destination_airport_iata}
                    `
                  }))}
                  onChange={L => {
                    handleChangeCostItemField('approve_request_leg', L?.value);
                  }}
                />
              </div>
            </div>

            <div className='w3-col s12 m6 l6'>
              <div>
                <label> Service Location </label>
                <Select
                  className='w-full small-top-margin small-bottom-margin'
                  value={selectedCostListItem?.service_location
                    ? { value: selectedCostListItem?.service_location, label: selectedCostListItem?.service_location }
                    : null
                  }
                  options={legs &&
                    legs?.filter(L => L?.id == selectedCostListItem?.approve_request_leg)
                      ?.reduce((acc, L) => (
                        [
                          {
                            value: `${L?.departure_airport_iata} / ${L?.departure_airport_icao} `,
                            label: `${L?.departure_airport_iata} / ${L?.departure_airport_icao}`
                          },
                          {
                            value: `${L?.destination_airport_iata} / ${L?.destination_airport_icao}`,
                            label: `${L?.destination_airport_iata} / ${L?.destination_airport_icao}`
                          }
                        ]
                      ), [])
                  }
                  onChange={L => {
                    handleChangeCostItemField('service_location', L?.value);
                  }}
                />
              </div>
            </div>

            <div className='w3-col s12 m6 l6'>
              <div>
                <label> Select Service</label>
                <Select
                  className='w-full small-top-margin small-bottom-margin'
                  options={
                    FilteredRolesServices && FilteredRolesServices?.data?.map(S => ({
                      value: S?.id,
                      label: S?.name
                    }))}
                  onChange={service => {
                    handleChangeCostItemField('service_id', service?.value);
                    // handleChangeCostItemField('service_name', service?.label);
                  }}
                  value={FilteredRolesServices && FilteredRolesServices?.data?.find(S => S?.id == selectedCostListItem?.service_id) &&
                  {
                    label: FilteredRolesServices?.data?.find(S => S?.id == selectedCostListItem?.service_id)?.name,
                    value: FilteredRolesServices?.data?.find(S => S?.id == selectedCostListItem?.service_id)?.id
                  }
                  }
                />
              </div>
            </div>

            <div className='w3-col s12 m6 l6'>
              <div>
                <label> Quantity </label>
                <input
                  type='number'
                  value={selectedCostListItem?.quantity}
                  className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                  onChange={e => handleChangeCostItemField('quantity', e.target.value)}
                  onBlur={e => handleChangeCostItemField('quantity', Number(e.target.value))}
                />
              </div>
            </div>

            <div className='w3-col s12 m6 l6'>
              <div>
                <label> Crew Count </label>
                <input
                  type='number'
                  value={selectedCostListItem?.crew_count}
                  className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                  onChange={e => handleChangeCostItemField('crew_count', e.target.value)}
                  onBlur={e => handleChangeCostItemField('crew_count', Number(e.target.value))}
                />
              </div>
            </div>

            <div className='w3-col s12 m6 l6'>
              <div>
                <label> Unit Cost </label>
                <input
                  type='number'
                  value={selectedCostListItem?.unit_cost}
                  className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                  onChange={e => handleChangeCostItemField('unit_cost', e.target.value)}
                  onBlur={e => handleChangeCostItemField('unit_cost', Number(e.target.value)?.toFixed(2))}
                />
              </div>
            </div>

            <div className='w3-col s12 m6 l6'>
              <div>
                <label> VAT % </label>
                <input
                  type='number'
                  value={selectedCostListItem?.vat_percentage}
                  className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                  onChange={e => handleChangeCostItemField('vat_percentage', e.target.value)}
                  onBlur={e => handleChangeCostItemField('vat_percentage', Number(e.target.value)?.toFixed(2))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='py-1 px-2'>
          <div className="h-3"></div>
          <div className="flex justify-end">
            <button onClick={handleCloseCostListItemModal} className='w3-btn w3-grey w3-text-white'> Close </button>
            <button
              onClick={handleSaveCostListItem}
              disabled={
                !selectedCostListItem?.approve_request_leg ||
                !selectedCostListItem?.service_id ||
                isNaN(selectedCostListItem?.quantity) ||
                isNaN(selectedCostListItem?.unit_cost)
              }
              className='w3-btn bg-primary-blue w3-text-white small-left-margin'
            >
              Confirm
            </button>
          </div>
          <div className="h-3"></div>
        </div>
      </div>
    </Modal>
  )
};

export default CostListItemModal;