// Packages
import React from "react";
import { useNavigate } from "react-router-dom";

function InvoiceReportsSelectionView() {

  const Navigate = useNavigate();

  // *********** Handlers ***********
  
  const routeToReportingScreen = (route) => {
    Navigate(`/invoice-reports${route}`);
  };

  // *********** Render Functions ***********

  const CONTENT = () => (
    <div className='border-primary-blue w3-round p-2'>
      <ul id="invoice-report-selection-list">
        <li>
          <div className="cursor-pointer" onClick={e => routeToReportingScreen('/aircraft-invoices')}> Aircraft Wise List </div>
        </li>
        <li>
          <div className="cursor-pointer" onClick={e => routeToReportingScreen('/airbase-invoices')}> Airbase Wise List </div>
        </li>
        <li>
          <div className="cursor-pointer" onClick={e => routeToReportingScreen('/filtered-invoices')}> Filtered List </div>
        </li>
      </ul>
    </div>
  );

  return (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Invoice Reporting </span>
      {CONTENT()}
    </div>
  );
}

export default InvoiceReportsSelectionView;
