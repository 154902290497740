// Packages
import React from "react";
import Select from "react-select";
import dayjs from "dayjs";

// Utils
import { AirportSelect } from "../../../../components";

function CustomFilters(props) {
    return (
        <div className={`w3-container w3-filtered-area w3-light p-2 ${props?.showFilters ? 'w3-show' : 'w3-hide'}`}>
            <div className="">
                <div className="w3-row mx-n1">
                    <div className='px-1 w3-col m4 s12 w3-margin-bottom'>
                        <label className='w3-medium'> Departure </label>
                        <AirportSelect
                            placeholder='Search by airport'
                            inputClass='no-focus w3-border'
                            containerClass='w-full'
                            onKeyUp={(e) => props.handleRemoveAirport('from', e?.target?.value)}
                            handleOptionSelect={(e, A) => props?.handleChangeFilters('from', A?.[0])}
                        />
                    </div>
                    <div className='px-1 w3-col m4 s12 w3-margin-bottom'>
                        <label className='w3-medium'> Destination </label>
                        <AirportSelect
                            placeholder='Search by airport'
                            inputClass='no-focus w3-border'
                            containerClass='w-full'
                            onKeyUp={(e) => props.handleRemoveAirport('to', e?.target?.value)}
                            handleOptionSelect={(e, A) => props?.handleChangeFilters('to', A?.[0])}
                        />
                    </div>
                    <div className='px-1 w3-col m4 s12 w3-margin-bottom'>
                        <label className='w3-medium'> Include </label>
                        <select
                            value={props?.filters?.airportType}
                            className="w3-white w3-input no-focus w3-border w-full"
                            onChange={e => props?.handleChangeFilters('airportType', e.target.value)}
                        >
                            <option value='AND'>Both destination & departure </option>
                            <option value='OR'>Either destination or departure </option>
                        </select>
                    </div>

                    <div className='px-1 w3-col m4 s12 w3-margin-bottom'>
                        <label className='w3-medium'> Departure Date Range </label>
                        <input
                            type='date'
                            className={`
                            ${props?.filterErrors?.from_range_error ? 'error-field' : ''} 
                            w3-input no-focus w3-border w-full`
                            }
                            value={props?.filters?.from_range_one ? dayjs(props?.filters?.from_range_one).format('YYYY-MM-DD') : ''}
                            onBlur={e => props?.handleFormatDateFilter('from_range_one', e.target.value)}
                            onChange={e => props?.handleChangeFilters('from_range_one', e.target.value)}
                        />
                    </div>
                    <div className='px-1 w3-col m4 s12 w3-margin-bottom'>
                        <label className='w3-medium'> &nbsp;</label>
                        <input
                            type='date'
                            className={`
                            ${props?.filterErrors?.from_range_error ? 'error-field' : ''}  
                            w3-input no-focus w3-border w-full`
                            }
                            min={props?.filters?.from_range_one ? dayjs(props?.filters?.from_range_one).format('YYYY-MM-DD') : ''}
                            value={props?.filters?.from_range_two ? dayjs(props?.filters?.from_range_two).format('YYYY-MM-DD') : ''}
                            onBlur={e => props?.handleFormatDateFilter('from_range_two', e.target.value)}
                            onChange={e => props?.handleChangeFilters('from_range_two', e.target.value)}
                        />
                    </div>

                    <div className='px-1 w3-col m4 s12 w3-margin-bottom'>
                        <label className='w3-medium'> Include </label>
                        <select
                            value={props?.filters?.dateType}
                            className="w3-white w3-input no-focus w3-border w-full"
                            onChange={e => props?.handleChangeFilters('dateType', e.target.value)}
                        >
                            <option value='AND'>Both arrival & departure dates</option>
                            <option value='OR'>Either arrival or departure dates</option>
                        </select>
                    </div>

                    <div className='px-1 w3-col m4 s12 w3-margin-bottom'>
                        <label className='w3-medium'> Arrrival Date Range </label>
                        <input
                            type='date'
                            className={`
                            ${props?.filterErrors?.to_range_error ? 'error-field' : ''}  
                            w3-input no-focus w3-border w-full`
                            }
                            value={props?.filters?.to_range_one ? dayjs(props?.filters?.to_range_one).format('YYYY-MM-DD') : ''}
                            onBlur={e => props?.handleFormatDateFilter('to_range_one', e.target.value)}
                            onChange={e => props?.handleChangeFilters('to_range_one', e.target.value)}
                        />
                    </div>
                    <div className='px-1 w3-col m4 s12 w3-margin-bottom'>
                        <label className='w3-medium'>&nbsp;</label>
                        <input
                            type='date'
                            className={`
                            ${props?.filterErrors?.to_range_error ? 'error-field' : ''} 
                            w3-input no-focus w3-border w-full`
                            }
                            min={props?.filters?.to_range_one ? dayjs(props?.filters?.to_range_one).format('YYYY-MM-DD') : ''}
                            value={props?.filters?.to_range_two ? dayjs(props?.filters?.to_range_two).format('YYYY-MM-DD') : ''}
                            onBlur={e => props?.handleFormatDateFilter('to_range_two', e.target.value)}
                            onChange={e => props?.handleChangeFilters('to_range_two', e.target.value)}
                        />
                    </div>
                    <div className='px-1 w3-col m4 s12 w3-margin-bottom aircraft-filter-select-container'>
                        <label className='w3-medium'>&nbsp;</label>
                        <Select
                            isClearable={true}
                            placeholder="Select Aircraft"
                            menuPortalTarget={document.body}
                            onChange={A => props?.handleChangeFilters('aircraft_id', A?.value || '')}
                            options={props?.aircrafts?.map(A => (
                                { label: A?.tailnumber, value: A?.id }
                            ))}
                        />
                    </div>
                </div>
                <div className="px-1">
                    <div className="flex justify-between">
                        <ul className="filter-tabs-list">
                            <li>
                                <button
                                    className={`${props?.filters?.tabInView === 'requests' ? 'active' : ''} flex items-center w3-btn bg-primary-blue w3-text-white ml-auto`}
                                    onClick={e => props?.handleChangeFilters('tabInView', 'requests')}
                                >
                                    Requests
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`${props?.filters?.tabInView === 'cost-plus-invoice' ? 'active' : ''} flex items-center w3-btn bg-primary-blue w3-text-white ml-auto`}
                                    onClick={e => props?.handleChangeFilters('tabInView', 'cost-plus-invoice')}
                                >
                                    Cost Invoices
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`${props?.filters?.tabInView === 'block-hour-invoice' ? 'active' : ''} flex items-center w3-btn bg-primary-blue w3-text-white ml-auto`}
                                    onClick={e => props?.handleChangeFilters('tabInView', 'block-hour-invoice')}
                                >
                                    Block Hour Invoices
                                </button>
                            </li>
                        </ul>
                        <button onClick={props?.handleApplyFilters} className="flex items-center w3-btn bg-primary-blue w3-text-white ml-auto">Apply</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomFilters;