// Packages
import React from "react";
import dayjs from "dayjs";

// Utils
import { Cancel, Edit, Eye, ChevronRight, Complete } from "../../../../components";

function CustomInsuranceRequestTable(props) {
    const CUSTOM_VIEW_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-view-fsr-btn"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        View
                    </span>
                    <Eye className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-view-fsr-btn"
    };

    const CUSTOM_DELETE_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-edit-fsr-btn small-left-margin"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        Edit
                    </span>
                    <Cancel className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-edit-fsr-btn"
    };

    const CUSTOM_EDIT_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-edit-fsr-btn small-left-margin"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        Edit
                    </span>
                    <Edit className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-edit-fsr-btn"
    };

    const CUSTOM_COMPLETE_BTN = {
        "render":
            <button
                style={{ "color": "black" }}
                className={"custom-delete-fsr-btn small-left-margin"}
            >
                <span className="w3-tooltip">
                    <span className='tooltip-text w3-text w3-tag w-12'>
                        Cancel
                    </span>
                    <Complete className='h-5 w-5' />
                </span>
            </button>
        ,
        "className": "custom-delete-fsr-btn"
    };

    return (
        <div className='custom-data-table react-table-lite-container'>
            <table className='react-table-lite-table' cellSpacing={0}>
                <thead className='react-table-lite-header'>
                    <tr>
                        <th></th>
                        {props?.headers?.map((h, index) => (
                            <th key={index}>
                                <div>{h?.replaceAll('_', ' ')}</div>
                            </th>
                        ))}
                        <th> <div> Actions </div></th>
                    </tr>
                </thead>
                <tbody>
                    {props?.insuranceRequestList?.length < 1 && props?.isLoading
                        ? <tr>
                            <td colSpan={props?.headers?.length + 2}> <div>Loading data...</div> </td>
                        </tr>
                        : props?.insuranceRequestList?.length < 1 && !props?.isLoading
                            ? <tr><td colSpan={props?.headers?.length + 2}> <div>No data found</div></td></tr>
                            : null
                    }
                    {props?.insuranceRequestList && props?.insuranceRequestList?.map((InsuranceReq, index) => {
                        const row = <React.Fragment key={index}>
                            <tr className='react-table-lite-row'>
                                <td>
                                    {!InsuranceReq?.status?.toLowerCase()?.includes('cancel') &&
                                        <div className='cursor-pointer' onClick={e => props?.toggleExpandInsuranceRequest(InsuranceReq)}>
                                            <ChevronRight
                                                className={`w-4 h-4 ${props?.expandedInsuranceRequestListIds?.find(id => id == InsuranceReq?.id) ? 'rotate-90' : ''}`}
                                            />
                                        </div>
                                    }
                                </td>

                                <td><div>{InsuranceReq?.id}</div></td>
                                <td><div>{InsuranceReq?.location}</div></td>
                                <td><div>{InsuranceReq?.fleet_name}</div></td>
                                <td><div>{InsuranceReq?.tailnumber}</div></td>
                                <td><div>{InsuranceReq?.medical_insurance_request_crew_member?.length}</div></td>
                                <td><div>{dayjs(InsuranceReq?.date_duration_from)?.format('YYYY-MM-DD')}</div></td>
                                <td><div>{dayjs(InsuranceReq?.date_duration_to)?.format('YYYY-MM-DD')}</div></td>
                                <td>
                                    <div
                                        className={{
                                            'draft': 'w3-text-grey',
                                            'Approved': 'w3-text-blue',
                                            'Completed': 'text-success',
                                            'Cancelled': 'w3-text-red',
                                        }[InsuranceReq?.status]}
                                    >
                                        {InsuranceReq?.status}
                                    </div>
                                </td>
                                <td className='rtl-table-actions'>
                                    <div className='rtl-action-btn-container flex'>
                                        {
                                            <span onClick={e => props?.routeToViewInsuranceRequest(InsuranceReq?.id)}>
                                                {CUSTOM_VIEW_BTN.render}
                                            </span>
                                        }
                                        {
                                            !InsuranceReq?.status?.toLowerCase()?.includes('completed') &&
                                                !InsuranceReq?.status?.toLowerCase()?.includes('cancel')
                                                ? <span onClick={e => props?.routeToEditInsuranceRequest(InsuranceReq?.id)}>
                                                    {CUSTOM_EDIT_BTN.render}
                                                </span>
                                                : null
                                        }
                                        {
                                            InsuranceReq?.status?.toLowerCase()?.includes('draft')
                                                ? <span onClick={e => props?.handleApproveRequest(InsuranceReq)}>
                                                    {CUSTOM_COMPLETE_BTN.render}
                                                </span>
                                                : null
                                        }
                                        {
                                            InsuranceReq?.status?.toLowerCase()?.includes('draft') ||
                                                InsuranceReq?.status?.toLowerCase()?.includes('approved')
                                                ? <span onClick={e => props?.handleCancelRequest(InsuranceReq)}>
                                                    {CUSTOM_DELETE_BTN.render}
                                                </span>
                                                : null
                                        }
                                    </div>
                                </td>
                            </tr>
                            {
                                props?.expandedInsuranceRequestListIds?.find(id => id == InsuranceReq?.id) &&
                                props?.approvedInsuranceRequestList
                                    ?.filter(item => item?.medical_insurance_request == InsuranceReq?.id)
                                    ?.map((approvedInsuranceRequest, index) => (
                                        <tr className={`w3-pale-indigo react-table-lite-row ${index === 0 ? 'approved-row' : ''} `} key={index}>
                                            <td></td>
                                            <td><div>{`${InsuranceReq?.id}-${approvedInsuranceRequest?.version}`}</div></td>
                                            <td><div>{approvedInsuranceRequest?.location}</div></td>
                                            <td><div>{approvedInsuranceRequest?.fleet_name}</div></td>
                                            <td><div>{approvedInsuranceRequest?.tailnumber}</div></td>
                                            <td><div>{approvedInsuranceRequest?.approve_medical_insurance_request_crew_member?.length}</div></td>
                                            <td><div>{dayjs(approvedInsuranceRequest?.date_duration_from)?.format('YYYY-MM-DD')}</div></td>
                                            <td><div>{dayjs(approvedInsuranceRequest?.date_duration_to)?.format('YYYY-MM-DD')}</div></td>
                                            <td><div className='capitalize'>{approvedInsuranceRequest?.status || approvedInsuranceRequest?.flight_log}</div></td>
                                            <td className="rtl-table-actions">
                                                {
                                                    // index == 0
                                                    true
                                                        ? <div className='rtl-action-btn-container flex items-center'>
                                                            <span onClick={e => props?.routeToApprovedViewInsuranceRequest(approvedInsuranceRequest?.id)} className='cursor-pointer'>
                                                                {CUSTOM_VIEW_BTN.render}
                                                            </span>
                                                            {
                                                                approvedInsuranceRequest?.status?.toLowerCase()?.includes('approved') &&
                                                                    props?.isStaff
                                                                    ? <span onClick={e => props?.handleCompleteRequest(approvedInsuranceRequest)}>
                                                                        {CUSTOM_COMPLETE_BTN.render}
                                                                    </span>
                                                                    : null
                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </td>
                                        </tr>
                                    ))
                            }
                            {
                                props?.expandedInsuranceRequestListIds?.find(id => id == InsuranceReq?.id) &&
                                    props?.loadingApprovedinsuranceRequestList?.find(id => id == InsuranceReq?.id)
                                    ? <tr>
                                        <td className='w3-pale-indigo'></td>
                                        <td className='w3-pale-indigo' colSpan={props?.headers?.length + 2}>
                                            <div> Loading history... </div>
                                        </td>
                                    </tr>
                                    : null
                            }
                            {
                                props?.expandedInsuranceRequestListIds?.find(id => id == InsuranceReq?.id) &&
                                    !props?.loadingApprovedinsuranceRequestList?.find(id => id == InsuranceReq?.id) &&
                                    !props?.approvedInsuranceRequestList?.find(item => item?.medical_insurance_request == InsuranceReq?.id)
                                    ? <tr>
                                        <td className='w3-pale-indigo'></td>
                                        <td className='w3-pale-indigo' colSpan={props?.headers?.length + 2}>
                                            <div> No approved reservations history </div>
                                        </td>
                                    </tr>
                                    : null
                            }
                        </React.Fragment>;
                        return row;
                    })}
                </tbody>
            </table>
        </div>
    )
};

export default CustomInsuranceRequestTable;