// Packages
import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { ContractCostPlusInvoicesListRequest } from '../../../../requests';

// Utils
import { auth } from '../../../../atoms';
import { InvoiceReportTable, Loader } from '../../../../components';
import { constants, decodeString, authenticationErrorHandle } from '../../../../utils';

export default function ExportAircraftInvoiceForm({ contract, handleClose, handleExportInvoice }) {

  const [authState, _authState] = useAtom(auth);
  const [contractCostPlusInvoicesItems, _contractCostPlusInvoicesItems] = useState({});
  const [contractBlockHourInvoicesItems, _contractBlockHourInvoicesItems] = useState({});
  const [selectedContract, _selectedContract] = useState('');
  const [selectedMonth, _selectedMonth] = useState('');
  const [selectedYear, _selectedYear] = useState(dayjs().format('YYYY'));
  const [invoiceType, _invoiceType] = useState('cost-plus');
  const [isLoading, _isLoading] = useState(false);

  // Fetch the invoice items based on filters
  useEffect(() => {
    if (authState && selectedContract && selectedMonth && selectedYear)
      getContractInvoiceItems();
  }, [authState, selectedContract, selectedMonth, selectedYear])

  const getContractInvoiceItems = () => {
    _isLoading(true);
    const token = decodeString(authState);
    const contractId = parseInt(selectedContract);
    const data = JSON.stringify({
      month: parseInt(selectedMonth),
      year: parseInt(selectedYear),
    });
    ContractCostPlusInvoicesListRequest(token, contractId, data)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData?.invoice_service_data) {
          _isLoading(false);
          _contractCostPlusInvoicesItems(old => ({
            invoice_items: nonPaginatedData?.invoice_service_data?.service_data &&
              nonPaginatedData?.invoice_service_data?.service_data.map((item => ({
                ...item,
                month: dayjs(`${selectedYear}-${selectedMonth}-01`)?.format('MMM-YYYY')
              }))),
            total: nonPaginatedData?.invoice_service_data?.total_stats?.net_total_cost || 0
          }));
          _contractBlockHourInvoicesItems(old => ({
            invoice_items: nonPaginatedData?.block_hour_usage?.block_hour_data &&
              nonPaginatedData?.block_hour_usage?.block_hour_data.map((item => ({
                ...item,
                month: dayjs(`${selectedYear}-${selectedMonth}-01`)?.format('MMM-YYYY')
              }))),
            total: nonPaginatedData?.block_hour_usage?.total_stats?.total_blockhours || 0
          }));
        } else {
          _contractCostPlusInvoicesItems({});
          throw "Request Failed";
        }
      })
      .catch(err => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch contract invoices" });
      })
  };

  // *********** Handlers ***********

  const handleContractChange = (e) => {
    _selectedContract(e.target.value);
  };

  const handleMonthChange = (e) => {
    _selectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    _selectedYear(e.target.value);
  };

  const handleInvoiceTypeChange = (value) => {
    _invoiceType(value);
  };

  const handleSubmit = (e) => {
    if (!selectedContract || !selectedMonth) return;
    const data = JSON.stringify(
      {
        contract_id: Number(selectedContract),
        month: Number(selectedMonth),
        year: Number(selectedYear)
      }
    );
    handleExportInvoice(data);
  }

  // *********** Render Functions ***********

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const AIRCRAFT_INVOICE_FORM_FIELDS = () => (
    <div>
      <div className='w3-row-padding w3-stretch'>
        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Contract </label>
          <select
            id="contract"
            value={selectedContract}
            onChange={handleContractChange}
            className='w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Contract </option>
            {contract &&
              contract?.data &&
              contract?.data
                ?.map((F, index) => (
                  <option value={F.id} key={index}> {F.name} </option>
                ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='month'> Month </label>
          <select
            id='month'
            value={selectedMonth}
            onChange={handleMonthChange}
            className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Month </option>
            {constants?.MONTHS?.map((option, index) => <option key={option} value={index + 1}> {option} </option>)}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='year'> Year </label>
          <select
            id='year'
            name='year'
            value={selectedYear}
            onChange={handleYearChange}
            className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Year </option>
            {Array.from({ length: parseInt(dayjs().year()) - 2024 + 1 }, (_, i) => i + 2024).map((item) => (
              <option value={item} key={item}> {item} </option>
            ))
            }
          </select>
        </div>

        <div className='w-full w3-row-padding'>
          <div className='w-full flex small-top-margin justify-end'>
            <button onClick={handleClose} className='w3-btn w3-text-white w3-grey small-right-margin'> Close </button>
            <button disabled={!selectedContract || !selectedMonth || !selectedYear} onClick={handleSubmit} className='w3-btn bg-primary-blue w3-text-white'> Export to ZATCA </button>
          </div>
        </div>

        <div className="w3-col m12 w3-bar w3-margin-top" >
          <div
            onClick={e => handleInvoiceTypeChange('cost-plus')}
            className={`w3-col w3-half w3-button ${invoiceType === "cost-plus" ? 'bg-primary-blue w3-text-white' : 'w3-light-grey'}`}
          >
            Cost Plus
          </div>
          <div
            onClick={e => handleInvoiceTypeChange('block-hours')}
            className={`w3-col w3-half w3-button ${invoiceType === "block-hours" ? 'bg-primary-blue w3-text-white' : 'w3-light-grey'}`}
          >
            Block Hours
          </div>
        </div>
      </div>
    </div>
  );

  const AIRCRAFT_INVOICE_PRE_EXPORT_DATA_VIEW = () => (
    <div className='list-view-container overflow-auto'>
      <InvoiceReportTable
        costPlusInvoiceItems={contractCostPlusInvoicesItems}
        blockHoursInvoiceItems={contractBlockHourInvoicesItems}
        isLoading={isLoading}
        invoiceType={invoiceType}
      />
    </div>
  );

  return (
    <div id="export-aircraft-invoices-form">
      {AIRCRAFT_INVOICE_FORM_FIELDS()}
      {isLoading ? LOADER() : AIRCRAFT_INVOICE_PRE_EXPORT_DATA_VIEW()}
    </div>
  )
};
