// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import Select from 'react-select';
import dayjs from 'dayjs';

// APIs
import { CrewMemberCreateRequest } from '../../../../requests';

// Utils
import { auth, loggedUserInfoCache } from '../../../../atoms';
import { constants, decodeString, authenticationErrorHandle, verifyFileSizeLessThanOneMb } from '../../../../utils';
import { FileUpload, Modal } from '../../../../components';

const CreateCrewMemberModal = ({
    isOpen,
    fleet,
    selectedFleet,
    onCrewMemberCreate,
    handleCloseAddCrewMemberModal
}) => {
    const INITIAL_FORM = {
        first_name: '',
        middle_name: '',
        last_name: '',
        crewname: '',
        saudia_id: '',
        passport: '',
        birth_date: '',
        license: '',
        nationality: '',
        crewtitle: '',
        fleet: '',
        company: '',
        passport_file: '',
        passport_file_name: '',
    };
    const [form, _form] = useState({ ...INITIAL_FORM });
    const [isSubmitting, _isSubmitting] = useState(false);
    const [authState, _authState] = useAtom(auth);
    const [loggedUserInfo] = useAtom(loggedUserInfoCache);

    // set fleet from props
    useEffect(() => {
        if (selectedFleet) {
            _form(old => ({
                ...old,
                fleet: selectedFleet
            }));
        }
    }, [selectedFleet, fleet]);

    // Set company from logged user Info
    useEffect(() => {
        if (loggedUserInfo?.data) {
            setCompany();
        }
    }, [loggedUserInfo?.data]);

    const setCompany = () => {
        _form(old => ({
            ...old,
            company: loggedUserInfo?.data?.company
        }));
    };

    // *********** Handlers ***********

    const handleCrewMemberFieldChange = (name, value) => {
        _form(old => ({
            ...old,
            [name]: value,
            crewname: name === 'first_name' ? value : old?.crewname
        }));
    };

    const handleCrewMemberPassportUpload = (e) => {
        if (verifyFileSizeLessThanOneMb(e.target.files[0].size))
            _form(old => ({
                ...old,
                passport_file: e.target.files[0],
                passport_file_name: e.target.files[0]?.name
            }));
        else Store.addNotification({ ...constants.ERRORTOAST, message: 'Passport size should be less than 1 MB' });
    };

    const handleSubmit = () => {
        const token = decodeString(authState);
        const data = JSON.parse(JSON.stringify(form));
        const form_data = new FormData();
        const JSONStringData = JSON.stringify(data);
        form_data.append('data', JSON.stringify(data));
        if (form?.passport_file)
            form_data.append('passport_file', form?.passport_file);
        _isSubmitting(true);
        CrewMemberCreateRequest(token, form_data)
            .then(res => {
                if (res && res?.status === 401) {
                    authenticationErrorHandle(() => _authState('0'));
                    return (
                        { errorCodes: constants.SESSIONTIMEOUT }
                    );
                } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
                    try {
                        res.json().then(backEndErrorMessageObj => {
                            Object.keys(backEndErrorMessageObj).forEach(key => {
                                Store.addNotification({ ...constants.ERRORTOAST, message: backEndErrorMessageObj?.[key]?.[0] });
                            })
                        })
                    } catch (err) {
                        console.err(err)
                    }
                    throw 'Request Failed';
                } else return (res.json())
            })
            .then(data => {
                if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
                if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
                    throw 'Request Failed';
                } else {
                    _isSubmitting(false);
                    Store.addNotification({ ...constants.SUCCESSTOAST, message: 'Crew member created' });
                    const newlyAddedCrewMember = { ...data };
                    onCrewMemberCreate(newlyAddedCrewMember, null);
                }
            })
            .catch(err => {
                _isSubmitting(false);
                console.error(err);
                Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
            });
    };

    // *********** Render Functions ***********

    const USER_DETAILS = () => (
        <React.Fragment>
            <div className='w3-row-padding'>
                <div className='w3-col l6 m6 s12'>
                    <label> Name </label>
                    <input
                        type='text'
                        value={form?.first_name}
                        onChange={e => handleCrewMemberFieldChange('first_name', e.target.value)}
                        className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                    />
                </div>
                <div className='w3-col l6 m6 s12'>
                    <label> Title </label>
                    <input
                        type='text'
                        value={form?.crewtitle}
                        onChange={e => handleCrewMemberFieldChange('crewtitle', e.target.value)}
                        className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                    />
                </div>
            </div>

            <div className='w3-row-padding'>
                <div className='w3-col l6 m6 s12'>
                    <label> Birth Date </label>
                    <input
                        max={dayjs().format('YYYY-MM-DD')}
                        type='date'
                        value={form?.birth_date}
                        onChange={e => handleCrewMemberFieldChange('birth_date', e.target.value)}
                        className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                    />
                </div>
                <div className='w3-col l6 m6 s12'>
                    <label> Saudia ID </label>
                    <input
                        type='text'
                        value={form?.saudia_id}
                        onChange={e => handleCrewMemberFieldChange('saudia_id', e.target.value)}
                        className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                    />
                </div>
            </div>

            <div className='w3-row-padding'>
                <div className='w3-col l6 m6 s12'>
                    <label> License </label>
                    <input
                        type='text'
                        value={form?.license}
                        onChange={e => handleCrewMemberFieldChange('license', e.target.value)}
                        className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                    />
                </div>
                <div className='w3-col l6 m6 s12'>
                    <label> Passport </label>
                    <input
                        type='text'
                        value={form?.passport}
                        onChange={e => handleCrewMemberFieldChange('passport', e.target.value)}
                        className='w3-input w3-border w3-round small-top-margin small-bottom-margin'
                    />
                </div>
            </div>

            <div className='w3-row-padding'>
                <div className='w3-col l6 m6 s12'>
                    <label> Nationality </label>
                    <Select
                        placeholder='Select Country'
                        options={constants.COUNTRYARRAY?.map(C => (
                            { value: C.country, label: C.country })
                        )}
                        value={form?.nationality ? {
                            value: form?.nationality,
                            label: form?.nationality
                        } : null}
                        onChange={A => handleCrewMemberFieldChange('nationality', A.value)}
                        className='w-full small-top-margin small-bottom-margin'
                    />
                </div>
                <div className='w3-col l6 m6 s12'>
                    <label> Fleet </label>
                    <div className='flex items-center'>
                        <Select
                            placeholder='Search Fleet'
                            options={fleet && fleet?.data?.map(F => (
                                { value: F.id, label: F.name })
                            )}
                            value={form?.fleet ? {
                                value: form?.fleet,
                                label: fleet && fleet?.data?.find(F => F.id == form?.fleet)?.name
                            } : null}
                            isDisabled={true}
                            onChange={F => null}
                            className='w-full small-top-margin small-bottom-margin'
                        />
                    </div>
                </div>

                <div className='w3-col l6 m6 s12'>
                    <FileUpload
                        id='passport_file'
                        name='passport_file'
                        label='Upload Passport'
                        accept={'application/pdf,image/*'}
                        isDownloadable={true}
                        file={form?.passport_file}
                        fileName={form?.passport_file_name}
                        onChange={handleCrewMemberPassportUpload}
                        labelClass='w3-btn bg-primary-blue w3-text-white small-top-margin'
                    />
                </div>
            </div>
        </React.Fragment>
    );

    const FORM_FOOTER = () => (
        <React.Fragment>
            <div className='w3-row-padding'>
                <div className='w3-col l12 m12 s12'>
                    <div className="flex justify-end">
                        <button onClick={handleCloseAddCrewMemberModal} className='w3-btn w3-grey w3-text-white'> Close </button>
                        <button
                            disabled={
                                isSubmitting ||
                                !(
                                    form?.first_name &&
                                    form?.fleet &&
                                    form?.passport
                                )
                            }
                            onClick={handleSubmit}
                            className='w3-btn bg-primary-blue w3-text-white small-left-margin'
                        >
                            {isSubmitting ? 'Submitting' : 'Submit'}
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    const USER_FORM = () => (
        <Modal isOpen={isOpen} modalClass='w3-round-medium' modalContainerClass='z-100'>
            <div className='flex w-full justify-between items-center px-2'>
                <h3> Crew Member </h3>
            </div>
            <div className='w3-round p-2'>
                {USER_DETAILS()}
                {FORM_FOOTER()}
            </div>
        </Modal>
    );

    return (
        <React.Fragment>
            {USER_FORM()}
        </React.Fragment>
    );
}

export default CreateCrewMemberModal;