import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import dayjs from 'dayjs';

// APIs
import { CostPlusInvoiceListRequest } from '../../../requests';

// Utils
import { constants, decodeString, authenticationErrorHandle, ignoreTimeZone } from '../../../utils';
import {auth} from '../../../atoms';
import { TableLite, Edit, Plus, Refresh } from '../../../components';

function CostPlusInvoiceList() {

    const Navigate = useNavigate();
    const [isLoading, _isLoading] = useState(false);
    const [costPlusInvoices, _costPlusInvoices] = useState([]);
    const [authState, _authState] = useAtom(auth);
  
    // Get users either from cache or from server
    useEffect(() => {
      if (authState) {
        getCostPlusInvoices();
      }
    }, [authState]);
  
    const getCostPlusInvoices = (page = undefined) => {
      const token = decodeString(authState);
      _isLoading(true);
      CostPlusInvoiceListRequest(token, page).then(res => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return (res.json())
      }).then(
        data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if (data) {
            // Keep server data in local state with current time
            _costPlusInvoices({
              data: [...data?.map(invoice => (
                {
                  ...invoice,
                  invoice_number: invoice?.invoice_number || '-',
                  createdat: dayjs(ignoreTimeZone(invoice?.createdat))?.format('YYYY-MM-DD')
                }
              ))],
              pagination: {
                currentPage: page,
                totalPages: Math.ceil(data?.count / constants.PAGINATIONPERPAGE)
              },
              created: Date.now()
            });
            _isLoading(false);
          } else {
            throw 'Request Failed';
          }
        }
      )
        .catch(
          err => {
            _isLoading(false);
            console.error(err);
            Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch cost plus invoices' });
          }
        )
    };
  
    const CostPlusInvoiceheaders = ["id", "request_id", "createdat"];
  
    const CustomHeaders = {
      "id": "Invoice Id",
      "request_id": "Request Id",
      "invoice_number": "Invoice No.",
      "createdat": "Created Date",
    };
  
    // *********** Handlers ***********
  
    const handlePaginate = (pageNo) => {
        getCostPlusInvoices(pageNo);
    };
  
    const routeToEditCostPlusInoviceInvoice = (id) => {
      Navigate(`/costplus-invoices/edit/${id}`);
    };
  
    const routeToViewCostPlusInoviceInvoice = (id) => {
      Navigate(`/costplus-invoices/view/${id}`);
    };

    // *********** Render Functions ***********
  
    const CUSTOM_EDIT_BTN = {
      "render":
        <button
          style={{ "color": "black" }}
          className={"custom-edit-fsr-btn small-left-margin"}
        >
          <span className="w3-tooltip">
            <span className='tooltip-text w3-text w3-tag w-12'>
              Edit
            </span>
            <Edit className='h-5 w-5' />
          </span>
        </button>
      ,
      "className": "custom-edit-fsr-btn"
    };
  
    const CONTENT = () => (
      <div className='page-content w3-white h-full relative overflow-hidden'>
        <div className='py-2'>
          {/* {NEW_USER_BUTTON()} */}
          <div className='list-view-container overflow-auto'>
            <TableLite
              showActions={true}
              data={costPlusInvoices && costPlusInvoices?.data || []}
              headers={CostPlusInvoiceheaders}
              customHeaders={CustomHeaders}
              actionTypes={['edit', 'view']}
              showPagination={true}
              totalPages={costPlusInvoices?.pagination?.totalPages || 1}
              currentPage={costPlusInvoices?.pagination?.currentPage || 1}
              renderEdit={CUSTOM_EDIT_BTN}
              onRowEdit={(event, row) => routeToEditCostPlusInoviceInvoice(row.id)}
              onRowView={(event, row) => routeToViewCostPlusInoviceInvoice(row.id)}
              onPaginate={(pageNo) => handlePaginate(pageNo)}
              cellStyle={{ fontSize: '0.8em' }}
              noDataMessage={isLoading ? 'Loading data...' : 'No data found'}
            />
          </div>
        </div>
      </div>
    );
  
    return (
      <div>
        {CONTENT()}
      </div>
    )
  }
  
  export default CostPlusInvoiceList;