// Packages
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Store } from 'react-notifications-component';
import dayjs from "dayjs";

// Apis
import { ApprovedFsrLegDetailsRequest } from "../../../../requests";

// Utils
import { authenticationErrorHandle, constants, decodeString, ignoreTimeZone } from "../../../../utils";
import { auth } from "../../../../atoms";
import { Loader } from "../../../../components";

function CompleteFsrForm({
    approvedFsr,
    handleCloseRequestCompleteModal,
    handleCompleteFsr,
    isSubmitting
}) {
    let copyOfFsr = {};
    const [authState, _authState] = useAtom(auth);
    const [approvedFsrDetails, _approvedFsrDetails] = useState({});
    const [dateTimeErrors, _dateTimeErrors] = useState([]);
    const [isLoading, _isLoading] = useState(false);

    useEffect(() => {
        if (approvedFsr?.request) {
            getApprovedFsrLegDetails(approvedFsr?.request);
        }
    }, [approvedFsr]);

    const getApprovedFsrLegDetails = (requestId) => {
        const token = decodeString(authState);
        _isLoading(true);
        ApprovedFsrLegDetailsRequest(token, requestId).then(res => {
            if (res && res?.status === 401) {
                authenticationErrorHandle(() => _authState('0'));
                return (
                    { errorCodes: constants.SESSIONTIMEOUT }
                );
            } else return (res.json())
        }).then(data => {
            if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
            if (data) {
                _approvedFsrDetails({
                    ...data,
                    approve_request_leg: [...data?.approve_request_leg]?.map(L => {
                        return ({
                            ...L,
                            departure_actual_time: L?.departure_time,
                            arrival_actual_time: L?.arrival_time,
                            dep_time_string: dayjs(ignoreTimeZone(L?.departure_time))?.format('HH:mm'),
                            arr_time_string: dayjs(ignoreTimeZone(L?.arrival_time))?.format('HH:mm')
                        })
                    })
                });
                copyOfFsr = { ...data };
                _isLoading(false);
            } else {
                throw 'Request Failed';
            }
        }
        ).catch(
            err => {
                _isLoading(false);
                console.error(err);
                Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch approved request history' });
            }
        );
    };

    // *********** Handlers ***********

    const handleDateTimeChange = (legId, e) => {
        const Legs = [...approvedFsrDetails?.approve_request_leg];
        Legs.forEach((L, index) => {
            if (e.target.name == 'departure_date' && legId == L?.id) {
                if (e.target.value) {
                    const departure_actual_time = dayjs(L?.departure_actual_time)
                        .set('date', Number(dayjs(e.target.value)?.format('DD')))
                        .set('month', Number(dayjs(e.target.value)?.format('MM')) - 1)
                        .set('year', Number(dayjs(e.target.value)?.format('YYYY')))
                        .toISOString();
                    L.departure_actual_time = departure_actual_time;
                }
            }
            else if (e.target.name == 'departure_time' && legId == L?.id) {
                const dep_time_string = e.target.value;
                L.dep_time_string = dep_time_string;
                const tempDate = dayjs(`2001-01-01T${dep_time_string}:00Z`);
                if (tempDate.isValid()) {
                    const departure_actual_time = tempDate
                        .set('date', Number(dayjs(L.departure_actual_time)?.format('DD')))
                        .set('month', Number(dayjs(L.departure_actual_time)?.format('MM')) - 1)
                        .set('year', Number(dayjs(L.departure_actual_time)?.format('YYYY')))
                        .toISOString();
                    L.departure_actual_time = departure_actual_time;
                }
            }
            else if (e.target.name == 'arrival_date' && legId == L?.id) {
                if (e.target.value) {
                    const arrival_actual_time = dayjs(L?.arrival_actual_time)
                        .set('date', Number(dayjs(e.target.value)?.format('DD')))
                        .set('month', Number(dayjs(e.target.value)?.format('MM')) - 1)
                        .set('year', Number(dayjs(e.target.value)?.format('YYYY')))
                        .toISOString();
                    L.arrival_actual_time = arrival_actual_time;
                }
            }
            else if (e.target.name == 'arrival_time' && legId == L?.id) {
                const arr_time_string = e.target.value;
                L.arr_time_string = arr_time_string;
                const tempDate = dayjs(`2001-01-01T${arr_time_string}:00Z`);
                if (tempDate.isValid()) {
                    const arrival_actual_time = tempDate
                        .set('date', Number(dayjs(L.arrival_actual_time)?.format('DD')))
                        .set('month', Number(dayjs(L.arrival_actual_time)?.format('MM')) - 1)
                        .set('year', Number(dayjs(L.arrival_actual_time)?.format('YYYY')))
                        .toISOString();
                    L.arrival_actual_time = arrival_actual_time;
                }
            }
            _approvedFsrDetails(old => ({
                ...old,
                approve_request_leg: [...Legs]
            }));
        });
    };

    const handleFormatTimeOnBlur = (legId, e) => {
        const Legs = [...approvedFsrDetails?.approve_request_leg];
        Legs.forEach((L, index) => {
            if (e.target.name == 'departure_time' && legId == L?.id) {
                L.dep_time_string = dayjs(ignoreTimeZone(L?.departure_actual_time))?.format('HH:mm');
            }
            else if (e.target.name == 'arrival_time' && legId == L?.id) {
                L.arr_time_string = dayjs(ignoreTimeZone(L?.arrival_actual_time))?.format('HH:mm');
            }
        })
        _approvedFsrDetails(old => ({
            ...old,
            approve_request_leg: [...Legs]
        }));
        handleCheckDateTimeValidation();
    };

    const handleCheckDateTimeValidation = () => {
        const Legs = [...approvedFsrDetails?.approve_request_leg];
        const errors = []
        Legs.forEach((L, index) => {
            const current_departure_actual_time = L?.departure_actual_time;
            const current_arrival_actual_time = L?.arrival_actual_time;
            const next_departure_actual_time = Legs[index + 1]?.departure_actual_time;
            if (dayjs(current_departure_actual_time) > dayjs(current_arrival_actual_time)) {
                errors.push({ legId: L?.id, type: 'departure' });
                Store.addNotification({ ...constants.ERRORTOAST, message: "Departure datetime can't be greater than arrival datetime" });
            }
            else if (next_departure_actual_time &&
                dayjs(current_arrival_actual_time) > dayjs(next_departure_actual_time)
            ) {
                errors.push({ legId: L?.id, type: 'arrival' });
                Store.addNotification({ ...constants.ERRORTOAST, message: "Arrival datetime can't be greater than next leg's departure" });
            }
        });
        _dateTimeErrors(errors);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (dateTimeErrors.length < 1) {
            handleCompleteFsr(e, approvedFsrDetails);
        }
    };

    const FORM_VIEW = () => (
        <React.Fragment>
            <div>
                {approvedFsr &&
                    approvedFsr?.request &&
                    approvedFsrDetails &&
                    approvedFsrDetails?.approve_request_leg
                    ? approvedFsrDetails?.approve_request_leg.map((L, index) => (
                        <div key={index}>
                            <div className="w3-large text-primary-blue small-bottom-margin">
                                {`
                                    ${L?.departure_airport_icao}/${L?.departure_airport_iata}
                                    → 
                                    ${L?.destination_airport_icao}/${L?.destination_airport_iata}
                                `}
                            </div>
                            <span className='w3-medium'> Actual Departure Time </span>
                            <div className="flex wrap-medium">
                                <input
                                    type='date'
                                    name='departure_date'
                                    value={dayjs(L?.departure_actual_time)?.format('YYYY-MM-DD')}
                                    onChange={e => handleDateTimeChange(L?.id, e)}
                                    onBlur={handleCheckDateTimeValidation}
                                    className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
                                    style={dateTimeErrors?.find(E => E?.legId === L?.id && E?.type === 'departure')
                                        ? { background: '#f5dada' }
                                        : {}
                                    }
                                />
                                <input
                                    type='text'
                                    name='departure_time'
                                    max={5}
                                    placeholder="00:00"
                                    value={L?.dep_time_string}
                                    onBlur={e => handleFormatTimeOnBlur(L?.id, e)}
                                    onChange={e => handleDateTimeChange(L?.id, e)}
                                    className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
                                    style={dateTimeErrors?.find(E => E?.legId === L?.id && E?.type === 'departure')
                                        ? { background: '#f5dada' }
                                        : {}
                                    }
                                />
                            </div>
                            <span className='w3-medium'> Actual Arrival Time </span>
                            <div className="flex">
                                <input
                                    type='date'
                                    name='arrival_date'
                                    value={dayjs(L?.arrival_actual_time)?.format('YYYY-MM-DD')}
                                    onChange={e => handleDateTimeChange(L?.id, e)}
                                    onBlur={handleCheckDateTimeValidation}
                                    className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
                                    style={dateTimeErrors?.find(E => E?.legId === L?.id && E?.type === 'arrival')
                                        ? { background: '#f5dada' }
                                        : {}
                                    }
                                />
                                <input
                                    type='text'
                                    name='arrival_time'
                                    max={5}
                                    placeholder="00:00"
                                    value={L?.arr_time_string}
                                    onBlur={e => handleFormatTimeOnBlur(L?.id, e)}
                                    onChange={e => handleDateTimeChange(L?.id, e)}
                                    className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
                                    style={dateTimeErrors?.find(E => E?.legId === L?.id && E?.type === 'arrival')
                                        ? { background: '#f5dada' }
                                        : {}
                                    }
                                />
                            </div>
                        </div>
                    ))
                    : null
                }
            </div>
            <div className="h-3"></div>
            <div className="flex justify-end">
                <button onClick={handleCloseRequestCompleteModal} className='w3-btn w3-grey w3-text-white'> Close </button>
                <button disabled={isSubmitting} onClick={handleSubmit} className='w3-btn w3-green w3-text-white small-left-margin'>
                    {isSubmitting ? 'Processing' : 'Confirm'}
                </button>
            </div>
        </React.Fragment>
    );

    const LOADER = () => (
        <div className="request-form-container">
            <div className="h-30 flex justify-center items-center">
                <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
                    <p className='text-primary-blue'> Loading data... </p>
                </div>
            </div>
        </div>
    );

    return (
        <div className='complete-request'>
            {isLoading
                ? LOADER()
                : FORM_VIEW()
            }
        </div>
    )
};

export default CompleteFsrForm;